import { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import StartScreenModal, { ModalMode } from './StartScreenModal'
import ModalButton from './ModalButton'
import { CustomInput, CustomPasswordInput } from '../Custom/CustomInput'
import BetonLogo from '../../Assets/BetonLogo.png'
import ASKLogoComponent from './AssetComponents/ASKLogoComponent'
import ASKBackgroundComponent from './AssetComponents/ASKBackgroundComponent'

import { authDriver, getNearestShipments } from '../../API/driverAPI'
import { getTrackOrder } from '../../API/clientAPI'
import CustomNotification from '../Custom/CustomNotification'
import TwoPanelLayout from '../Custom/TwoPanelLayout'
import { IsMobileContext } from '../Client/Context/WindowSizeContext'
import { DarkModeContext, DarkModeSwitchContext } from '../../Theme/StylesWrapperContext'

interface IStart {
    mode: 'driver' | 'client'
}

const Start: React.FC<IStart> = ({ mode }) => {

    const navigate = useNavigate()
    const isMobile = useContext(IsMobileContext)
    const isDarkMode = useContext(DarkModeContext)
    const setIsDarkMode = useContext(DarkModeSwitchContext)
    const { control, handleSubmit, setError } = useForm()
    const [isModalOpen, setModalOpen] = useState(false)
    const [modalMode, setModalMode] = useState<ModalMode | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isPending, setIsPending] = useState(false)

    useEffect(() => {
        if (mode === 'client') {
            document.title = 'АСК Бетон - Для клиентов'
        } else {
            document.title = 'АСК Бетон - Для водителей'
        }
    }, [mode])

    const driverErrorHandler = (error: string) => {
        setError('login', {})
        setError('password', {})
        setErrorMessage(error)
    }
    const clientErrorHandler = (error: string) => {
        setError('order', {})
        setErrorMessage(error)
    }

    const formData = (data: { order?: string, login?: string, password?: string }) => {
        setIsPending(true)
        if (mode === 'client') {
            if (data.order === 'demo') {
                setIsPending(false)
                navigate('/client/demo')
            } else {
                getTrackOrder(data.order).then(res => {
                    setIsPending(false)
                    if (res.data.result === 'OK') {
                        navigate(`/client/${data.order}`)
                    } else {
                        if (res.data.code === 404) {
                            clientErrorHandler('Заказ не существует')
                        } else if (res.data.code === 500) {
                            clientErrorHandler('Некорректный номер заказа')
                        } else {
                            clientErrorHandler('Неизвестная ошибка')
                        }
                    }
                }).catch(() => {
                    setIsPending(false)
                    setErrorMessage('Сервер недоступен')
                })
            }
        } else {
            if (data.login === 'demo' && data.password === 'demo') {
                setIsPending(false)
                navigate('/driver/demo')
            } else authDriver(data.login, data.password).then(auth => {
                setIsPending(false)
                if (auth.data.result === 'OK') {
                    getNearestShipments(auth.data.data).then(res => {
                        if (res.data.result === 'OK') {
                            navigate(`/driver/${auth.data.data}`)
                        } else {
                            driverErrorHandler(res.data.data)
                        }
                    })
                } else {
                    driverErrorHandler('Неверный логин или пароль')
                }
            }).catch(() => {
                setIsPending(false)
                setErrorMessage('Сервер недоступен')
            })
        }
    }

    return <>
        {!!setIsDarkMode && <Tooltip title='Сменить тему оформления' placement='left'><Fab
            variant='circular'
            color='primary'
            size='small'
            sx={{ display: 'flex', position: 'absolute', bottom: '1rem', right: '1rem', zIndex: 9999 }}
            onClick={() => {
                localStorage.setItem('darkMode', !isDarkMode ? '1' : '0')
                setIsDarkMode(!isDarkMode)
            }}
        >
            {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
        </Fab></Tooltip>}
        <StartScreenModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} mode={modalMode} />
        <CustomNotification message={errorMessage} setMessage={setErrorMessage} />
        <TwoPanelLayout
            left={<Box
                sx={{
                    width: '100%',
                    px: 4,
                    py: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    flexGrow: 1
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <Box component="img" src={BetonLogo} alt="BetonLogo" sx={{ width: '90%', maxWidth: '350px', borderRadius: '10px' }} />
                    <Typography component="h1" variant={isMobile ? 'h6' : "h5"} align='center' sx={{ width: '90%', maxWidth: '450px', pointerEvents: 'none', userSelect: 'none' }}>
                        {mode === 'client' ? 'ОТСЛЕЖИВАЙТЕ СВОИ ЗАКАЗЫ УДОБНО И ПРОСТО' : 'МЫ ЦЕНИМ РАБОТУ И ВРЕМЯ НАШИХ ВОДИТЕЛЕЙ'}
                    </Typography>
                </Box>
                <Typography
                    component="h1"
                    variant="subtitle2"
                    align="center"
                    sx={{ mt: 1, pointerEvents: 'none', userSelect: 'none' }}
                >
                    {mode === 'client' ? 'Введите код заказа для дальнейшего просмотра' : 'Вся нужная информация о рейсах. Просто авторизуйтесь'}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit(formData)} sx={{ mt: 1, width: '100%', maxWidth: '450px', flexGrow: 1 }}>
                    {mode === 'client' &&
                        <CustomInput
                            name="order"
                            label="Код заказа"
                            control={control}
                            defaultValue=''
                        />
                    }
                    {mode === 'driver' && <>
                        <CustomInput
                            required
                            name="login"
                            label="Ваш логин"
                            control={control}
                            defaultValue=''
                        />
                        <CustomPasswordInput
                            name="password"
                            label="Ваш пароль"
                            control={control}
                            defaultValue=''
                        />
                    </>}
                    <Button
                        type="submit"
                        fullWidth
                        disabled={isPending}
                        variant="contained"
                        sx={{ my: 2, textTransform: 'none' }}
                        startIcon={isPending ? <CircularProgress size={16} /> : undefined}
                    >
                        {mode === 'client' ? 'Найти' : 'Вход'}
                    </Button>
                    <Box sx={{ textAlign: 'center' }}>
                        {mode === 'client' && <ModalButton setModalOpen={setModalOpen} setModalMode={setModalMode} mode={'noorder'}>
                            Что делать, если я не знаю код?
                        </ModalButton>}
                        {mode === 'driver' && <>
                            <ModalButton setModalOpen={setModalOpen} setModalMode={setModalMode} mode={'forgotpass'}>
                                Забыли пароль?
                            </ModalButton>
                            <ModalButton setModalOpen={setModalOpen} setModalMode={setModalMode} mode={'nopass'}>
                                Еще не зарегистрированы?
                            </ModalButton>
                        </>}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', bottom: 0 }}>
                    <Button sx={{ textTransform: 'none' }} onClick={() => navigate(mode === 'client' ? '/driver' : '/client')} variant='text'>{mode === 'client' ? 'Версия для водителей' : 'Версия для клиентов'}</Button>
                </Box>
                {isMobile && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }}>
                    <ASKLogoComponent />
                </Box>}
            </Box>}
            right={<div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                height: '90%',
                width: '90%'
            }}>
                <ASKLogoComponent />
                <ASKBackgroundComponent isDark={isDarkMode} />
            </div>}
        />
    </>
}

export default Start