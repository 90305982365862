import ASKLogo from '../../../Assets/ASKLogo.svg'
import ASKLogoLight from '../../../Assets/ASKLogoLight.svg'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { useContext } from 'react'
import { IsMobileContext } from '../../Client/Context/WindowSizeContext'
import { DarkModeContext } from '../../../Theme/StylesWrapperContext'

const ASKLogoText: React.FC = ({ children }) => {

    const isMobile = useContext(IsMobileContext)

    return <Typography variant="h4" fontWeight={500} color='primary' fontSize={isMobile ? '1rem' : '2.125rem'} sx={{ pointerEvents: 'none', userSelect: 'none' }}>
        {children}
    </Typography>
}

const ASKLogoComponent: React.FC = () => {

    const isMobile = useContext(IsMobileContext)
    const isDarkMode = useContext(DarkModeContext)

    return <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <Link href='https://ask-glonass.ru/' target='_blank'><Box component="img" src={isDarkMode ? ASKLogoLight : ASKLogo} width={isMobile ? '90px' : '120px'} /></Link>
        <Box sx={{ width: '5px' }} bgcolor={(theme) => theme.palette.primary.main} />
        <Box width={isMobile ? 'min-content' : 'max-content'}>
            <ASKLogoText>
                {'ЦЕМЕНТНО'}
            </ASKLogoText>
            <ASKLogoText>
                {'БЕТОННЫЕ ЗАВОДЫ'}
            </ASKLogoText>
        </Box>
    </div>
}

export default ASKLogoComponent