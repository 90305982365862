import api from './API'

interface IAuthDriverResponse {
    data: string
    result: string
    code?: number
}

export interface ILocation {
    lat: number
    lon: number
}

interface IShipment {
    address: string
    comment: string
    factory: string
    id: string
    load_begin: string
    load_loc: ILocation
    number: string
    phone: string
    unload_begin: string
    unload_loc: ILocation
    way: {
        width: number
        points: [number, number][]
    }
}

interface IGetNearestShipments {
    code: number
    data: string
    details: string
    result: string
    current: IShipment
    next: IShipment
}

export const authDriver = (login?: string, password?: string) => {
    return api.get<IAuthDriverResponse>(`/main?func=auth&out=json&login=${login}&password=${password}`)
}

export const getNearestShipments = (session: string) => {
    return api.get<IGetNearestShipments>(`/beton?func=getnearestshipments&sess=${session}&utc`)
}

export const logoutDriver = (session: string) => {
    return api.get(`/main?func=logout&sess=${session}`)
}