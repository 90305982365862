import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ModalMode } from './StartScreenModal'

interface IModalButton {
    mode: 'noorder' | 'nopass' | 'forgotpass'
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setModalMode: React.Dispatch<React.SetStateAction<ModalMode | undefined>>
}

const ModalButton: React.FC<IModalButton> = ({mode, setModalOpen, setModalMode, children}) => {
    return <Box>
        <Button onClick={() => {
            setModalOpen(true)
            setModalMode(mode)
        }} variant='text' size='small' sx={{ textTransform: 'none' }}>{children}</Button>
    </Box>
}

export default ModalButton