import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

interface ICustomNotification {
    message: string
    setMessage: React.Dispatch<React.SetStateAction<string>>
}

const CustomNotification: React.FC<ICustomNotification> = ({ message, setMessage }) => {
    return <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={() => setMessage('')}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
        <Alert onClose={() => setMessage('')} severity="error" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
}

export default CustomNotification