import { useParams, useNavigate } from "react-router-dom"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ErrorIcon from '@mui/icons-material/Error';

const ErrorPage: React.FC<{mode: string}> = ({mode}) => {

	const params = useParams()
    const navigate = useNavigate()

	const handleBackTo = () => {
		navigate(`/${mode}`)
	}

	return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flexGrow: 1, height: '100vh', gap: 2}}>
		<ErrorIcon sx={{fontSize: '200px'}} color='error' />
		<Typography variant='h5'>{params.error}</Typography>
		<Button sx={{ textTransform: 'none' }} onClick={handleBackTo} variant='contained'>На главную</Button>
	</Box>
}

export default ErrorPage