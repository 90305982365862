import { Buffer } from "buffer"

const BYTE_ORDER = [3, 2, 1, 0, 5, 4, 7, 6, 8, 9, 10, 11, 12, 13, 14, 15];

function convertNumberToHexa(value: number) {
	let hex = value <= 0xf ? '0' : '';
	hex += value.toString(16);
	return hex;
}

function uint8ArrayToString(value: Uint8Array) {
	let guid = ''

	for (let i = 0; i < 16; i++) {
		guid += i === 4 || i === 6 || i === 8 || i === 10 ? '-' : ''

		const pos = BYTE_ORDER[i]
		guid += convertNumberToHexa(value[pos])
	}
	return guid
}

export function base64ToUUID(basevalue?: string) {
	try {
		if (!!basevalue) {
			const value = basevalue.replace(/-/g, '+').replace(/_/g, '/') + '=='
			const UintArray = Buffer.from(value, 'base64')
			return uint8ArrayToString(new Uint8Array(UintArray))
		} else return ''
	} catch {
		return 'Base64 to UUID Error'
	}
}
