import GlobalStyles from '@mui/material/GlobalStyles'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import WindowSizeContextProvider from '../Components/Client/Context/WindowSizeContext'
import { darkTheme, lightTheme, scrollbarStyle } from './Theme'

export const DarkModeContext = createContext(false)
export const DarkModeSwitchContext = createContext<Dispatch<SetStateAction<boolean>> | null>(null)

const StylesWrapperContext: React.FC = ({ children }) => {

    const [isDarkMode, setIsDarkMode] = useState(false)
    const savedDarkMode = localStorage.getItem('darkMode')
    useEffect(() => {
        if (!!savedDarkMode) {
            setIsDarkMode(!!Number(savedDarkMode))
        }
    }, [savedDarkMode])

    return <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <DarkModeContext.Provider value={isDarkMode}>
            <DarkModeSwitchContext.Provider value={setIsDarkMode}>
                <WindowSizeContextProvider>
                    <CssBaseline />
                    <GlobalStyles styles={() => scrollbarStyle(isDarkMode)} />
                    {children}
                </WindowSizeContextProvider>
            </DarkModeSwitchContext.Provider>
        </DarkModeContext.Provider>
    </ThemeProvider>
}

export default StylesWrapperContext